export const InternalAudit3 = {
  date: "2021-11-01",
  duration: "1 min read",
  author: "Amelia Smith",
  title: "InternalAudit3 Economy and the Stock Market",
  description: "InternalAudit3 Economy and the Stock Market",
  img: "https://source.unsplash.com/800x600/?InternalAudit3",
  content: [
    {
      type: "headingBig",
      value: "InternalAudit3 Economy",
      color: "black75",
    },
    {
      type: "bodyBig",
      value:
        "InternalAudit3y is the largest economy in Europe and the fourth-largest in the world. It is known for its strong manufacturing sector, which includes automobiles, machinery, and chemicals. The country has a highly skilled workforce and a strong focus on innovation and technology. InternalAudit3y is also a major exporter, with products being sold all over the world. The InternalAudit3 economy is known for its stability and resilience, and it has weathered many economic crises over the years.",
      color: "black50",
    },
    {
      type: "headingSmall",
      value: "InternalAudit3 Stock Market",
      color: "black75",
    },
    {
      type: "bodySmall",
      value:
        "The InternalAudit3 stock market is one of the largest in Europe and is home to many well-known companies, such as Volkswagen, BMW, and Siemens. The main stock index in InternalAudit3y is the DAX, which tracks the performance of the 30 largest companies listed on the Frankfurt Stock Exchange. The InternalAudit3 stock market has a long history and is known for its stability and transparency. It is an important source of capital for InternalAudit3 companies and plays a key role in the country's economy.",
      color: "black50",
    },
    {
      type: "img",
      value: "https://source.unsplash.com/800x600/?InternalAudit3-economy",
      label: "InternalAudit3 Economy",
      alt: "InternalAudit3 Economy",
    },
    {
      type: "break",
      value: "",
    },
    {
      type: "ul",
      value: [
        "InternalAudit3y is the largest economy in Europe and the fourth-largest in the world.",
        "The country has a strong manufacturing sector and is known for its innovation and technology.",
        "The InternalAudit3 stock market is one of the largest in Europe and is home to many well-known companies.",
        "The main stock index in InternalAudit3y is the DAX, which tracks the performance of the 30 largest companies listed on the Frankfurt Stock Exchange.",
      ],
      color: "black50",
    },
    {
      type: "ol",
      value: [
        "InternalAudit3y is the largest economy in Europe and the fourth-largest in the world.",
        "The country has a strong manufacturing sector and is known for its innovation and technology.",
        "The InternalAudit3 stock market is one of the largest in Europe and is home to many well-known companies.",
        "The main stock index in InternalAudit3y is the DAX, which tracks the performance of the 30 largest companies listed on the Frankfurt Stock Exchange.",
      ],
      color: "black50",
    },
  ],
};
