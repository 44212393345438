/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

function CenteredBlogCard({ image, title, description, action }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            width: "100%",
            height: "auto",
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox
        p={3}
        mt={-1}
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        flexGrow={1}
      >
        <MKTypography item display="inline" variant="h5Light" color="black75">
          {title}
        </MKTypography>
        <MKBox item mt={1} mb={3}>
          <MKTypography variant="subtitle1" component="p" color="black50">
            {description}
          </MKTypography>
        </MKBox>
        {action.type === "external" ? (
          <MKButton
            item
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant={action.variant ? action.variant : "gradient"}
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            item
            component={Link}
            to={action.route}
            variant={action.variant ? action.variant : "gradient"}
            size="small"
            color={action.color ? action.color : "dark"}
          >
            {action.label}
          </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "blackAlt",
      "black75",
      "black50",
      "lightBlue",
    ]),
    variant: MKButton.propTypes.variant,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard;
