export const AuditForBusiness1 = {
  date: "2021-10-30",
  duration: "1 min read",
  author: "Amelia Smith",
  title: "AuditForBusiness1 Tax Benefits",
  description: "AuditForBusiness1 Tax Benefits",
  img: "https://source.unsplash.com/800x600/?AuditForBusiness1",
  content: [
    {
      type: "headingBig",
      value: "AuditForBusiness1 Tax Economy",
      color: "black75",
    },
    {
      type: "bodyBig",
      value:
        "AuditForBusiness1 Taxy is the largest economy in Europe and the fourth-largest in the world. It is known for its strong manufacturing sector, which includes automobiles, machinery, and chemicals. The country has a highly skilled workforce and a strong focus on innovation and technology. AuditForBusiness1 Taxy is also a major exporter, with products being sold all over the world. The AuditForBusiness1 Tax economy is known for its stability and resilience, and it has weathered many economic crises over the years.",
      color: "black50",
    },
    {
      type: "headingSmall",
      value: "AuditForBusiness1 Tax Stock Market",
      color: "black75",
    },
    {
      type: "bodySmall",
      value:
        "The AuditForBusiness1 Tax stock market is one of the largest in Europe and is home to many well-known companies, such as Volkswagen, BMW, and Siemens. The main stock index in AuditForBusiness1 Taxy is the DAX, which tracks the performance of the 30 largest companies listed on the Frankfurt Stock Exchange. The AuditForBusiness1 Tax stock market has a long history and is known for its stability and transparency. It is an important source of capital for AuditForBusiness1 Tax companies and plays a key role in the country's economy.",
      color: "black50",
    },
    {
      type: "img",
      value: "https://source.unsplash.com/800x600/?AuditForBusiness1",
      label: "AuditForBusiness1 Tax Economy",
      alt: "AuditForBusiness1 Tax Economy",
    },
    {
      type: "break",
      value: "",
    },
    {
      type: "ul",
      value: [
        "AuditForBusiness1 Taxy is the largest economy in Europe and the fourth-largest in the world.",
        "The country has a strong manufacturing sector and is known for its innovation and technology.",
        "The AuditForBusiness1 Tax stock market is one of the largest in Europe and is home to many well-known companies.",
        "The main stock index in AuditForBusiness1 Taxy is the DAX, which tracks the performance of the 30 largest companies listed on the Frankfurt Stock Exchange.",
      ],
      color: "black50",
    },
    {
      type: "ol",
      value: [
        "AuditForBusiness1 Taxy is the largest economy in Europe and the fourth-largest in the world.",
        "The country has a strong manufacturing sector and is known for its innovation and technology.",
        "The AuditForBusiness1 Tax stock market is one of the largest in Europe and is home to many well-known companies.",
        "The main stock index in AuditForBusiness1 Taxy is the DAX, which tracks the performance of the 30 largest companies listed on the Frankfurt Stock Exchange.",
      ],
      color: "black50",
    },
  ],
};
